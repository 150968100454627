import React, { useCallback, useEffect, useState, useMemo } from "react"
import UserInfo from "../components/MyPage/UserInfo"
import { userInfo } from "../components/Security/UserContext"
import { navigate } from "gatsby"
import ConfirmInfo from "../components/MyPage/ConfirmInfo"
import { fetchApi, patchApi, postApi } from "../components/Common/WebApi-utils"
import { createHistory, Redirect, Location } from "@reach/router"
import queryString from "query-string"
import Cookies from "js-cookie"
import FormAssessmentCopy from "../components/MyPage/FormAssessmentCopy"
import SampleAccess from "../components/MyPage/SampleAccess"
import Moment from "react-moment"
import "moment-timezone"

const IndexPage = () => {
  // let history = createHistory(window)
  const [user, setUser] = useState([])
  const [account, setAccount] = useState([])
  const [tibetAccess, setTibetAccess] = useState(false)
  const [updateInfo, setUpdateInfo] = useState(false)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    setloading(true)
    let queryParam
    if (location.search !== null) queryParam = queryString.parse(location.search)

    //Prøvetilgang
    // window.sessionStorage.setItem("productid", "79a06ddf-0b86-ea11-a811-000d3a3a73fd") //prøvetilgang
    // window.sessionStorage.setItem("producturl", "https://www.gyldendal.no/")
    // window.sessionStorage.setItem("redirecturl", queryParam.redirecturl)
    // window.sessionStorage.setItem("expiredate", "2021-03-25T07:22:45.814Z")

    //Vurderingseks
    window.sessionStorage.setItem("groupId", "155043") //vurdeks
    window.sessionStorage.setItem("redirecturl", queryParam.redirecturl)

    //Vent med dette
    //TODO: if new gyldendal user (that means there is no token in query pram, only firtsname, lastname and email)
    //Then show new user schema, after user is created in CRM, navigate back to identityapi (navigate(IdentityApiEndpoint + "/TibetIdentity/GyldendalUser/{mobilephone")) with mobilephone as queryparam

    //Get publication Access
    console.log("queryParam.Teacher")
    console.log(queryParam.Teacher)

    console.log("queryParam.token")
    console.log(queryParam.token)

    if (queryParam.token === undefined && queryParam.Teacher === undefined && queryParam.mobilephone === undefined) navigate(process.env.GATSBY_API_URL_ID + "/TibetIdentity")
    if (queryParam.Teacher === "false") {
      //TODO: ahow message: only for teachers
      console.log("HAHAH")
    }
    //TODO:navigate to New user schema and send mobilenr to api
    // if (mobilephone=null)
    //   navigate(IdentityApiEndpoint + "/TibetIdentity?mobilephone=48209393")

    //Get Contact
    if (queryParam.token) {
      console.log("queryParam.token")
      console.log(queryParam.token)
      window.sessionStorage.setItem("gyldendal_access_token", "Bearer " + queryParam.token)
      window.sessionStorage.setItem("gyldendal_access_token_expire", queryParam.token_expire)
      window.sessionStorage.setItem("usertype", queryParam.usertype)

      fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalContacts")
        .then((response) => {
          setUser(response.data)
          setAccount(response.data.account)
          //TODO: check verified date then nqavigate or show userinfo
          document.getElementById("headerName").textContent = response.data.fullname
          window.sessionStorage.setItem("accountId", response.data.account.accountid)
        })
        .catch((error) => {
          console.log("error")
          console.log(error)
        })
        .finally(function () {
          setloading(false)
        })

      var lastVerified = new Date(user.pp_profileverifiedon)
      var today = new Date()
      var dateChecker = new Date(today.setMonth(today.getMonth() - 3))
      lastVerified < dateChecker ? setUpdateInfo(true) : setUpdateInfo(false)

      if (window.sessionStorage.getItem("productid") && window.sessionStorage.getItem("producturl")) {
        setTibetAccess(true)
        const tibetApiData = {
          material: window.sessionStorage.getItem("productid"),
          accessUrl: window.sessionStorage.getItem("producturl"),
          accessFrom: new Date(),
          accessTo: new Date(window.sessionStorage.getItem("expiredate")),
        }
        console.log(tibetApiData)
        window.sessionStorage.setItem("tibetAccess", "true")
        postApi(process.env.GATSBY_API_URL_APIM + "/GyldendalTibetAccesses", tibetApiData)
          .then(() => {
            updateInfo ? navigate("/brukerprofil") : navigate(window.sessionStorage.getItem("redirecturl"))
          })
          .catch((error) => {
            console.log("error")
            console.log(error)
          })
      }
    }
  }, [])
  useMemo(() => { }, [user])

  console.log(updateInfo)
  console.log(tibetAccess)

  return (
    <>
      {/* Header tag from */}

      {/* Main here */}
      <main>
        <div className="justify-center py-6 sm:px-6 lg:px-8 bg-white">
          {/* <div>{!dateCheck ? <UserInfo /> : <FormAssessmentCopy />}</div> */}
          {/* {updateInfo && tibetAccess ? <UserInfo user={user} account={account} /> : <FormAssessmentCopy />} */}
          {updateInfo ? <UserInfo user={user} account={account} /> : <FormAssessmentCopy account={account} />}
        </div>
      </main>
    </>
  )
}

export default IndexPage
